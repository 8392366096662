// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-seo-js": () => import("./../../../src/pages/seo.js" /* webpackChunkName: "component---src-pages-seo-js" */),
  "component---src-pages-sv-blogg-js": () => import("./../../../src/pages/sv/blogg.js" /* webpackChunkName: "component---src-pages-sv-blogg-js" */),
  "component---src-pages-sv-cookie-policy-js": () => import("./../../../src/pages/sv/cookie-policy.js" /* webpackChunkName: "component---src-pages-sv-cookie-policy-js" */),
  "component---src-pages-sv-index-js": () => import("./../../../src/pages/sv/index.js" /* webpackChunkName: "component---src-pages-sv-index-js" */),
  "component---src-pages-sv-kontakt-js": () => import("./../../../src/pages/sv/kontakt.js" /* webpackChunkName: "component---src-pages-sv-kontakt-js" */),
  "component---src-pages-sv-om-unikorn-js": () => import("./../../../src/pages/sv/om-unikorn.js" /* webpackChunkName: "component---src-pages-sv-om-unikorn-js" */),
  "component---src-pages-sv-privacy-policy-js": () => import("./../../../src/pages/sv/privacy-policy.js" /* webpackChunkName: "component---src-pages-sv-privacy-policy-js" */),
  "component---src-pages-sv-seo-js": () => import("./../../../src/pages/sv/seo.js" /* webpackChunkName: "component---src-pages-sv-seo-js" */),
  "component---src-pages-sv-tack-js": () => import("./../../../src/pages/sv/tack.js" /* webpackChunkName: "component---src-pages-sv-tack-js" */),
  "component---src-pages-sv-unikorns-js": () => import("./../../../src/pages/sv/unikorns.js" /* webpackChunkName: "component---src-pages-sv-unikorns-js" */),
  "component---src-pages-sv-webbanalys-js": () => import("./../../../src/pages/sv/webbanalys.js" /* webpackChunkName: "component---src-pages-sv-webbanalys-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-unikorns-js": () => import("./../../../src/pages/unikorns.js" /* webpackChunkName: "component---src-pages-unikorns-js" */),
  "component---src-pages-web-analytics-js": () => import("./../../../src/pages/web-analytics.js" /* webpackChunkName: "component---src-pages-web-analytics-js" */),
  "component---src-templates-about-subpage-js": () => import("./../../../src/templates/about-subpage.js" /* webpackChunkName: "component---src-templates-about-subpage-js" */),
  "component---src-templates-blog-post-single-js": () => import("./../../../src/templates/blog-post-single.js" /* webpackChunkName: "component---src-templates-blog-post-single-js" */),
  "component---src-templates-marketing-subpage-js": () => import("./../../../src/templates/marketing-subpage.js" /* webpackChunkName: "component---src-templates-marketing-subpage-js" */),
  "component---src-templates-unikorns-subpage-js": () => import("./../../../src/templates/unikorns-subpage.js" /* webpackChunkName: "component---src-templates-unikorns-subpage-js" */),
  "component---src-templates-web-subpage-js": () => import("./../../../src/templates/web-subpage.js" /* webpackChunkName: "component---src-templates-web-subpage-js" */)
}

